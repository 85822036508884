var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container pt-4"
  }, [_vm.loadingAction.postPreview ? _c('div', [_c('Spinner', [_vm._v("Checking mapping...")])], 1) : _c('div', [_c('div', {
    staticClass: "mb-4"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-md-3"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-block",
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v("Submit")])])])]), _c('div', [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end mb-2"
  }, [_c('span', {
    staticClass: "font-w600 mr-2"
  }, [_vm._v(_vm._s(_vm.page) + " of " + _vm._s(Math.ceil(_vm.preview.length / _vm.perPage)))]), _c('button', {
    staticClass: "btn btn-link mr-1",
    class: {
      disabled: _vm.page <= 1
    },
    on: {
      "click": function click($event) {
        _vm.page--;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-chevron-left"
  })]), _c('button', {
    staticClass: "btn btn-link",
    class: {
      disabled: _vm.page >= Math.ceil(_vm.preview.length / _vm.perPage)
    },
    on: {
      "click": function click($event) {
        _vm.page++;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-chevron-right"
  })])]), _c('div', [_c('table', {
    staticClass: "table table-sm"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Field")]), _vm._l(Array.from(Array(_vm.perPage).keys()), function (colIdx) {
    return _c('th', {
      key: colIdx
    }, [_vm._v("Example " + _vm._s((_vm.page - 1) * _vm.perPage + colIdx + 1))]);
  })], 2)]), Object.keys(_vm.preview).length > 0 ? _c('tbody', _vm._l(_vm.setup.info.fields, function (field, key) {
    return _c('tr', {
      key: key
    }, [_c('th', [_vm._v(_vm._s(field.friendly))]), _vm._l(Array.from(Array(_vm.perPage).keys()), function (colIdx) {
      return _c('td', {
        key: colIdx
      }, [_vm._v(_vm._s(_vm.preview[(_vm.page - 1) * _vm.perPage + colIdx][key]))]);
    })], 2);
  }), 0) : _vm._e()])])]), _c('div', {
    staticClass: "d-flex justify-content-between mb-3"
  }, [_c('router-link', {
    staticClass: "btn btn-light",
    attrs: {
      "to": {
        name: 'import-mapping',
        query: _vm.$route.query,
        params: _vm.$route.params
      }
    }
  }, [_vm._v("Back")]), _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v("Submit")])], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-md-9"
  }, [_c('p', {
    staticClass: "font-w600 mb-1"
  }, [_vm._v(" Below is a preview of some of your data against their designated fields. Double-check everything looks okay. If you need to make any changes, hit the back button in the lower left. ")])]);
}]

export { render, staticRenderFns }