<template>
  <div class="container pt-4">
    <div v-if="loadingAction.postPreview">
      <Spinner>Checking mapping...</Spinner>
    </div>
    <div v-else>
      <div class="mb-4">
        <div class="row">
          <div class="col-md-9">
            <p class="font-w600 mb-1">
              Below is a preview of some of your data against their designated fields. Double-check everything looks okay. If you need to make any
              changes, hit the back button in the lower left.
            </p>
          </div>
          <div class="col-md-3"><button class="btn btn-primary btn-block" @click="onSubmit">Submit</button></div>
        </div>
      </div>
      <div>
        <div class="d-flex align-items-center justify-content-end mb-2">
          <span class="font-w600 mr-2">{{ page }} of {{ Math.ceil(preview.length / perPage) }}</span>
          <button class="btn btn-link mr-1" :class="{ disabled: page <= 1 }" @click="page--"><i class="fa fa-chevron-left"></i></button>
          <button class="btn btn-link" :class="{ disabled: page >= Math.ceil(preview.length / perPage) }" @click="page++">
            <i class="fa fa-chevron-right"></i>
          </button>
        </div>
        <div>
          <table class="table table-sm">
            <thead>
              <tr>
                <th>Field</th>
                <th v-for="colIdx in Array.from(Array(perPage).keys())" :key="colIdx">Example {{ (page - 1) * perPage + colIdx + 1 }}</th>
              </tr>
            </thead>
            <tbody v-if="Object.keys(preview).length > 0">
              <tr v-for="(field, key) in setup.info.fields" :key="key">
                <th>{{ field.friendly }}</th>
                <td v-for="colIdx in Array.from(Array(perPage).keys())" :key="colIdx">{{ preview[(page - 1) * perPage + colIdx][key] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="d-flex justify-content-between mb-3">
        <router-link :to="{ name: 'import-mapping', query: $route.query, params: $route.params }" class="btn btn-light">Back</router-link>
        <button class="btn btn-primary" @click="onSubmit">Submit</button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Spinner from '@/components/Spinner';

export default {
  name: 'ImportPreview',
  components: {
    Spinner
  },
  data() {
    return {
      status: 'pending',
      page: 1,
      perPage: 4
    };
  },
  computed: {
    ...mapGetters({
      import: 'import/import',
      setup: 'import/setup',
      mapping: 'import/mapping',
      preview: 'import/preview',
      errorAction: 'import/errorAction',
      loadingAction: 'import/loadingAction'
    })
  },
  async mounted() {
    if (!this.$route.params.id) {
      return this.$router.push({ name: 'import-list' });
    }

    if (this.import._id !== this.$route.params.id) {
      await this.getImport({ id: this.$route.params.id });
    }

    if (this.mapping.user.length === 0) {
      return this.$router.push({ name: 'import-mapping', params: this.$route.params });
    }

    if (!this.setup.info) {
      await this.getInfo({ type: this.import.type });
    }

    await this.postPreview({ id: this.import._id, mapping: this.mapping.user });

    if (this.preview.length < this.perPage) {
      this.perPage = this.preview.length;
    }
  },
  methods: {
    ...mapActions({
      getInfo: 'import/getInfo',
      getImport: 'import/get',
      postPreview: 'import/postPreview'
    }),
    ...mapMutations({
      setId: 'import/SET_ID',
      setUserMapping: 'import/SET_USER_MAPPING'
    }),
    onSubmit() {
      this.$router.push({ name: 'import-import', params: this.$route.params });
    }
  }
};
</script>
